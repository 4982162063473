import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import getSymbolFromCurrency from 'currency-symbol-map';
import Map from '@assets/images/map.svg';
import cookie from 'js-cookie';
import { sendEvent } from '../../../utils/amplitude';

import ArrowIcon from '../../../assets/images/grey-arrow.svg';

const SubscribeBlock = ({ onAuthClick, faceItLink, paymentPlans, currency, monthlyTrialPrice, trialPeriod }) => {
	const intl = useIntl();

	const [monthPrice, setMonthPrice] = useState(null);

	const handleButtonClick = () => {
		onAuthClick({ modalType: 'auth', location: 'step' });
	};

	useEffect(() => {
		if (!currency) return;
		if (!paymentPlans) return;
		if (!paymentPlans.month) return;

		const { month } = paymentPlans;

		setMonthPrice(month[currency] / 100);
	}, [paymentPlans, currency]);

	return (
		<div className="subscribe-block-container">
			<div className="subscribe-block-wrapper">
				<div className="subscribe-block-inner-wrapper">
					<div className="subscribe-block-title">{intl.formatMessage({ id: 'prematch.SubscribeBlockTitle' })}</div>

					{currency && (
						<div className="subscribe-block-action-block">
							<button
								className="subscribe-block-button"
								onClick={() => {
									handleButtonClick();
								}}
							>
								<span className="subscribe-block-button-title">
									{intl.formatMessage({ id: 'prematch.PurchaseSubscription' })}
								</span>
							</button>
						</div>
					)}
				</div>

				<div className="subscribe-block-image-wrapper">
					<img src={Map} alt="map" />
				</div>
			</div>
		</div>
	);
};

export default SubscribeBlock;
