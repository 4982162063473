import React, { useEffect, useState } from 'react';
import { CarouselCard } from '@components/atoms';
import { useIntl } from 'gatsby-plugin-react-intl';
import useResponsive from '../../../utils/useResponsive';

import Arrow from '@assets/images/arrow.svg';

import Card1 from '@assets/images/card-1.svg';
import Card2 from '@assets/images/card-2.svg';
import Card3 from '@assets/images/card-3.svg';
import Card4 from '@assets/images/card-4.svg';
import Card5 from '@assets/images/card-5.svg';
import Card6 from '@assets/images/card-6.svg';
import Card7 from '@assets/images/card-7.svg';
import Card8 from '@assets/images/card-8.svg';

import Card1Eng from '@assets/images/card-1-eng.svg';
import Card2Eng from '@assets/images/card-2-eng.svg';
import Card3Eng from '@assets/images/card-3-eng.svg';
import Card4Eng from '@assets/images/card-4-eng.svg';
import Card5Eng from '@assets/images/card-5-eng.svg';
import Card6Eng from '@assets/images/card-6-eng.svg';
import Card7Eng from '@assets/images/card-7-eng.svg';
import Card8Eng from '@assets/images/card-8-eng.svg';

import { sendEvent } from '../../../utils/amplitude';

const CarouselBlock = () => {
	const [isDesktop, isTablet, isMobile] = useResponsive();
	const intl = useIntl();

	const [counter, setCounter] = useState(0);
	const [disabled, setDisabled] = useState(false);

	const config = {
		autoload: false,
		itemsToBeVisible: 3,
		speed: 5000,
	};

	const CARD_LIST = [
		{
			imageUrl: intl.locale === 'ru' ? Card1 : Card1Eng,
			title: 'prematch.Cards.TitleOne',
			description: 'prematch.Cards.DescriptionOne',
			isAvailableNow: true,
			alt: 'aggression zones on CS2 map with description in scope prematch',
		},
		{
			imageUrl: intl.locale === 'ru' ? Card7 : Card7Eng,
			title: 'prematch.Cards.TitleSeven',
			description: 'prematch.Cards.DescriptionSeven',
			isAvailableNow: true,
			alt: 'scope prematch recommendation block',
		},
		{
			imageUrl: intl.locale === 'ru' ? Card2 : Card2Eng,
			title: 'prematch.Cards.TitleTwo',
			description: 'prematch.Cards.DescriptionTwo',
			isAvailableNow: true,
			alt: 'favourite probability positions of players on CS2 map with specified weapon in scope prematch',
		},
		{
			imageUrl: intl.locale === 'ru' ? Card3 : Card3Eng,
			title: 'prematch.Cards.TitleThree',
			description: 'prematch.Cards.DescriptionThree',
			isAvailableNow: true,
			alt: 'CS2 player playstyle and in-game role in scope prematch',
		},
		// {
		// 	imageUrl: intl.locale === 'ru' ? Card6 : Card6Eng,
		// 	title: 'prematch.Cards.TitleSix',
		// 	description: 'prematch.Cards.DescriptionSix',
		// 	isAvailableNow: true,
		// 	alt: 'CS2 player rank stats with detailed stats in scope prematch',
		// },
		// {
		// 	imageUrl: intl.locale === 'ru' ? Card4 : Card4Eng,
		// 	title: 'prematch.Cards.TitleFour',
		// 	description: 'prematch.Cards.DescriptionFour',
		// 	isAvailableNow: false,
		// 	alt: 'probability attack on plant on CS2 map in scope prematch',
		// },
		{
			imageUrl: intl.locale === 'ru' ? Card5 : Card5Eng,
			title: 'prematch.Cards.TitleFive',
			description: 'prematch.Cards.DescriptionFive',
			isAvailableNow: false,
			alt: 'effective grenades of enemies on CS2 map in scope prematch',
		},
		{
			imageUrl: intl.locale === 'ru' ? Card8 : Card8Eng,
			title: 'prematch.Cards.TitleEight',
			description: 'prematch.Cards.DescriptionEight',
			isAvailableNow: false,
			alt: 'prematch boosts and tricks',
		},
	];

	const SCROLL_COUNTER_LIMIT = isMobile ? CARD_LIST.length - 1 : CARD_LIST / 2;

	function navigate(position, config) {
		sendEvent('Prematch Landing / Benefits Scroll');

		const carouselEl = document.getElementById('carousel');
		const slideContainerEl = carouselEl.querySelector('.carousel__container');
		const slideEl = carouselEl.querySelector('.carousel__slide');
		let slideWidth = slideEl.offsetWidth;
		const newScrollPosition = getNewScrollPosition(position, slideContainerEl, slideWidth, config);

		if (newScrollPosition != undefined) {
			slideContainerEl.scrollLeft = newScrollPosition;
		}
	}

	function getNewScrollPosition(position, slideContainerEl, slideWidth, config) {
		const maxScrollLeft = slideContainerEl.scrollWidth - slideWidth * config.itemsToBeVisible;
		if (position === 'forward') {
			let x;

			if (isMobile) {
				x = slideContainerEl.scrollLeft + slideWidth;
			} else {
				x = slideContainerEl.scrollLeft + slideWidth + 12;
			}

			if (counter === SCROLL_COUNTER_LIMIT) {
			} else {
				setCounter(counter + 1);

				return x;
			}
		} else {
			setCounter(counter - 1);

			let x;

			if (isMobile) {
				x = slideContainerEl.scrollLeft - slideWidth;
			} else {
				x = slideContainerEl.scrollLeft - slideWidth - 12;
			}

			return x;
		}
	}

	useEffect(() => {
		if (counter === 0) {
			document.getElementById('carousel-button-left').style.visibility = 'hidden';
			document.getElementById('carousel-button-left-hover').style.visibility = 'hidden';
		}
	}, []);

	useEffect(() => {
		// if (counter === SCROLL_COUNTER_LIMIT) {
		// 	document.getElementById('carousel-button-right').style.visibility = 'hidden';
		// 	document.getElementById('carousel-button-right-hover').style.visibility = 'hidden';
		// } else {
		// 	document.getElementById('carousel-button-right').style.visibility = 'visible';
		// 	document.getElementById('carousel-button-right-hover').style.visibility = 'visible';
		// }
		// if (counter === 0) {
		// 	document.getElementById('carousel-button-left').style.visibility = 'hidden';
		// 	document.getElementById('carousel-button-left-hover').style.visibility = 'hidden';
		// } else {
		// 	document.getElementById('carousel-button-left').style.visibility = 'visible';
		// 	document.getElementById('carousel-button-left-hover').style.visibility = 'visible';
		// }
	}, [counter]);

	useEffect(() => {
		document.querySelector('#carousel__container').addEventListener('scroll', (event) => {
			const scrolled = document.querySelector('#carousel__container').scrollLeft;
			const scrolled2 =
				document.querySelector('#carousel__container').scrollWidth -
				(window.innerWidth > 1710 ? 1710 : window.innerWidth);

			if (scrolled > 50) {
				document.getElementById('carousel-button-left').style.visibility = 'visible';
				document.getElementById('carousel-button-left-hover').style.visibility = 'visible';
			} else {
				document.getElementById('carousel-button-left').style.visibility = 'hidden';
				document.getElementById('carousel-button-left-hover').style.visibility = 'hidden';
			}

			if (scrolled >= scrolled2 - 50) {
				document.getElementById('carousel-button-right').style.visibility = 'hidden';
				document.getElementById('carousel-button-right-hover').style.visibility = 'hidden';
			} else {
				document.getElementById('carousel-button-right').style.visibility = 'visible';
				document.getElementById('carousel-button-right-hover').style.visibility = 'visible';
			}
		});

		return () => {
			document.querySelector('#carousel__container').removeEventListener('scroll', () => {});
		};
	}, []);

	// TODO: COMBINE THIS HOOK WITH ABOVE HOOK
	useEffect(() => {
		if (isMobile) {
			document.getElementById('carousel-button-left-hover').style.display = 'none';
			document.getElementById('carousel-button-right-hover').style.display = 'none';
		}
	}, [counter]);

	useEffect(() => {
		if (disabled) {
			setTimeout(() => {
				setDisabled(false);
			}, 500);
		}
	}, [disabled]);

	return (
		<section class="carousel" id="carousel">
			<div class="carousel__wrapper">
				<div className="carousel__wrapper-header"></div>
				<div>
					<div class="carousel__content">
						<div className="carousel-button-left-hover" id="carousel-button-left-hover"></div>
						<div
							id="carousel-button-left"
							className="carousel-button-left"
							onClick={() => {
								if (disabled) return;

								setDisabled(true);

								navigate('backward', config);
							}}
						>
							<img src={Arrow} alt="left arrow" />
						</div>

						<div className="carousel-button-right-hover" id="carousel-button-right-hover"></div>
						<div
							id="carousel-button-right"
							className="carousel-button-right"
							onClick={() => {
								if (disabled) return;

								setDisabled(true);

								navigate('forward', config);
							}}
						>
							<img src={Arrow} alt="right arrow" />
						</div>
						<div class="carousel__container" id="carousel__container">
							{CARD_LIST.map((card) => (
								<div class="carousel__slide" key={card.title}>
									<CarouselCard
										imageUrl={card.imageUrl}
										title={card.title}
										description={card.description}
										isAvailableNow={card.isAvailableNow}
										imageAlt={card.alt}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CarouselBlock;
