import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Accordion } from '@components/atoms';
import getSymbolFromCurrency from 'currency-symbol-map';

const FAQBlock = ({ paymentPlans, currency, monthlyTrialPrice, trialPeriod }) => {
	const intl = useIntl();

	const [monthPrice, setMonthPrice] = useState(null);
	const [yearPrice, setYearPrice] = useState(null);
	const [yearPriceByMonth, setYearPriceByMonth] = useState(null);
	const [currencySymbol, setCurrencySymbol] = useState(null);

	useEffect(() => {
		if (!currency) return;
		if (!paymentPlans) return;
		if (!paymentPlans.month) return;

		const { month, year } = paymentPlans;

		setMonthPrice(month[currency] / 100);
		setYearPrice(year[currency] / 100);

		const yearPriceByMonth =
			intl.locale === 'ru' ? Math.floor(year[currency] / 100 / 12) : (year[currency] / 100 / 12).toFixed(2);

		setYearPriceByMonth(yearPriceByMonth);
		setCurrencySymbol(getSymbolFromCurrency(currency));
	}, [paymentPlans, currency]);

	const prematchSubscriptionLink = useMemo(() => {
		return intl.formatMessage({ id: 'prematch.prematchSubscriptionLink' });
	}, []);

	const prematchLink = useMemo(() => {
		return intl.formatMessage({ id: 'prematch.prematchLink' });
	}, []);

	const faq = {
		list: [
			{
				id: '1',
				question: 'prematch.Faq.QuestionOne',
				answer: 'prematch.Faq.AnswerOne',
				values: null,
			},
			{
				id: '2',
				question: 'prematch.Faq.QuestionTwo',
				answer: 'prematch.Faq.AnswerTwo',
				values: null,
			},
			{
				id: '3',
				question: 'prematch.Faq.QuestionThree',
				answer: 'prematch.Faq.AnswerThree',
				values: {
					prematchSubscriptionLink,
					prematchLink,
					trialPeriod,
				},
			},
			{
				id: '6',
				question: 'prematch.Faq.QuestionSix',
				answer: 'prematch.Faq.AnswerSix',
				values: {
					monthPrice,
					yearPrice,
					yearPriceByMonth,
					currencySymbol,
					trialPeriod,
				},
			},
			{
				id: '9',
				question: 'prematch.Faq.QuestionNine',
				answer: 'prematch.Faq.AnswerNine',
				values: null,
			},
			{
				id: '10',
				question: 'prematch.Faq.QuestionTen',
				answer: 'prematch.Faq.AnswerTen',
				values: null,
			},
			{
				id: '11',
				question: 'prematch.Faq.QuestionEleven',
				answer: 'prematch.Faq.AnswerEleven',
				values: null,
			},
		],
	};

	const faqList = faq.list;

	return (
		<div className="faq-main-container">
			<div className="faq-container">
				<div className="content-wrapper">
					<div className="title">{intl.formatMessage({ id: 'prematch.FaqBlockTitle' })}</div>

					<div className="faq-list-wrapper">
						{faqList.map((item) => (
							<Accordion
								key={item.id}
								id={item.id}
								title={item.question}
								description={item.answer}
								values={item.values}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQBlock;
