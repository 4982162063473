import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import ScopeLogoLarge from '@src/assets/images/scope-logo-large.svg';
import Social from '@components/molecules/Socials';

const Bottom = () => {
	const intl = useIntl();

	const BottomNavigation = [
		{
			title: 'prematch.Help',
			items: [
				{
					name: 'prematch.Guides',
					href: intl.locale === 'ru' ? 'https://scope.gg/guides/ru/' : 'https://scope.gg/guides/',
				},
				{
					name: 'prematch.Blog',
					href: intl.locale === 'ru' ? 'https://blog.scope.gg/ru/' : 'https://blog.scope.gg/',
				},
				{
					name: 'prematch.FAQ',
					href: intl.locale === 'ru' ? 'https://scope.gg/ru/faq' : 'https://scope.gg/faq',
				},
			],
		},
		{
			title: 'prematch.Legal',
			items: [
				{
					name: 'prematch.CookiePolicy',
					href: intl.locale === 'ru' ? 'https://app.scope.gg/ru/cookies' : 'https://app.scope.gg/cookies',
				},
				{
					name: 'prematch.ProductPrivacy',
					href: intl.locale === 'ru' ? 'https://app.scope.gg/ru/privacy' : 'https://app.scope.gg/privacy',
				},
				{
					name: 'prematch.ProductTerms',
					href: intl.locale === 'ru' ? 'https://app.scope.gg/ru/tos' : 'https://app.scope.gg/tos',
				},
			],
		},
	];

	const MailLinks = {
		title: 'prematch.ContactUs',
		items: [
			{ name: 'prematch.Email1', href: 'mailto:partnership@scope.gg', type: 'mail' },
			{ name: 'prematch.Email2', href: 'mailto:support@scope.gg', type: 'mail' },
		],
	};

	return (
		<div className="page-bottom__wrapper">
			<div className="page-bottom">
				<div className="page-bottom__logo">
					<img src={ScopeLogoLarge} alt="Scope.gg large logo" />
				</div>
				<div className="page-bottom__social">
					<Social />
				</div>

				<div className="bottom__nav-inner-wrapper">
					<div className="page-bottom__nav">
						{BottomNavigation.map((nav) => {
							return (
								<div className="bottom-nav__column">
									<div className="nav-column__title">{intl.formatMessage({ id: nav.title })}</div>
									<div className="column-items__wrapper">
										{nav.items.map((item) => {
											return (
												<a key={item.name} href={item.href} target="_blank" rel="noopener noreferrer">
													<span className="nav-column__item">{intl.formatMessage({ id: item.name })}</span>
												</a>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
					<div className="mail-links">
						<div className="nav-column__title">{intl.formatMessage({ id: MailLinks.title })}</div>
						<div className="column-items__wrapper">
							{MailLinks.items.map((item) => {
								return (
									<a key={item.name} href={item.href} rel="noopener noreferrer">
										<span className="nav-column__item-mail">{intl.formatMessage({ id: item.name })}</span>
									</a>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Bottom;
