import React from 'react';
import { useKey } from 'react-use';
import { useIntl } from 'gatsby-plugin-react-intl';

export default ({ onClose, children, width = '348px', showEscButton = true }) => {
	useKey('Escape', onClose);

	const intl = useIntl();

	return (
		<div className="modal-layout">
			<div className="scroll-container" onClick={(e) => e.currentTarget === e.target && onClose(e)}>
				<div className="content-wrapper">
					{showEscButton && (
						<div className="key-container" onClick={onClose} width={width}>
							<div className="key-wrapper">
								<div className="key">
									<div className="key-text">Esc</div>
								</div>
							</div>
							<div className="key-description">{intl.formatMessage({ id: 'prematch.Close' })}</div>
						</div>
					)}

					{children}
				</div>
			</div>
		</div>
	);
};
