import React from 'react';
import Social from '@components/molecules/Socials';
import { useIntl } from 'gatsby-plugin-react-intl';

import ExcorpLogoLarge from '@assets/images/excorp-logo-large.svg';

const Footer = () => {
	const intl = useIntl();

	return (
		<footer className="page-footer__wrapper">
			<div className="page-footer">
				<div className="footer__excorp-block">
					<div className="excorp-logo-wrapper">
						<a href={`https://excorp.gg${intl.locale === 'ru' ? '/ru' : ''}`}>
							<img src={ExcorpLogoLarge} alt="excorp.gg large logo" />
						</a>
					</div>
					<div className="excorp-info-wrapper">
						<span className="excorp-info">CS Virtual Trade Ltd, reg. no. HE 389299</span>
						<span className="excorp-info">
							Address: 705, Spyrou Araouzou & Koumantarias, Fayza House, 3036, Limassol, Cyprus
						</span>
					</div>
				</div>
				<div className="footer__socials-wrapper">
					<Social />
					<span className="footer-copyright">© 2024 SCOPE.GG</span>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
