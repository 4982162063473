import React from 'react';
import Header from '@components/molecules/Header';
import WithModals from '../prematch/modals/WithModals';

const Layout = (props) => {
	return (
		<div className="global-container">
			{props.children}
		</div>
	);
};

export default WithModals(Layout);
