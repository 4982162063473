import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout/layout';
import { sendEvent } from '../utils/amplitude';
import { isBrowser } from '../utils/ssr';
import { authService } from '../services/authService';
import { subscriptionService } from '../services/subscriptionService';
import { PAGE_LOAD_LOG_EVENT_DELAY } from '../utils/constants';
import AuthSourceChoiceModal from '../components/prematch/modals/AuthSourceChoiceModal';
import { sendDataLayerEvent } from '../utils/gtm';
import getSymbolFromCurrency from 'currency-symbol-map';
import Cookies from 'js-cookie';

import { subscription } from '@scope.gg/constants';

import Bottom from '../components/prematch/sections/Bottom';
import Footer from '../components/prematch/sections/Footer';

import FAQBlock from '@components/prematch/FAQBlock';
import SubscribeBlock from '@components/prematch/SubscribeBlock';
import CarouselBlock from '@components/prematch/CarouselBlock';
import Navbar from '@components/prematch/Navbar';
import StepsBlock from '@components/prematch/StepsBlock';
import HeroBlock from '@components/prematch/HeroBlock';

const PrematchAnalyticsPage = () => {
	const intl = useIntl();

	const [faceItLink, setFaceItLink] = useState('');
	const [modal, setModal] = useState(null);

	const [currency, setCurrency] = useState(null);
	const [paymentPlans, setPaymentPlans] = useState(null);
	const [promoCodeDetails, setPromoCodeDetails] = useState(null);

	useEffect(() => {
		checkingAuthorization();
		getCurrency();
		getPaymentPlans();
	}, []);

	useEffect(() => {
		if (isBrowser) {
			const appUrl = process.env.GATSBY_APP_URL;
			const backendUrl = process.env.GATSBY_API_URL;

			const faceItUrl = `${backendUrl}/faceIt/auth?successRedirectUrl=${appUrl}/auth-process?source=faceit&failureRedirectUrl=${appUrl}/auth-process?source=faceit`;
			const steamUrl = `${backendUrl}/steamProfiles/auth?redirectUrl=${appUrl}/auth-process?source=steam&backendUrl=${backendUrl}`;

			setFaceItLink(faceItUrl);
		}
	}, []);

	const init = async () => {
		try {
			await authService.getUserData();

			const response = await authService.getPrematchPromocodeDetails();

			setPromoCodeDetails(response);
		} catch (error) {
			console.error(error.message);
		}
	};

	const getCurrency = async () => {
		try {
			const response = await authService.getCurrency();

			setCurrency(response || 'USD');
		} catch (error) {
			console.error(error);

			setCurrency('USD');
		}
	};

	const getPaymentPlans = async () => {
		try {
			const response = await subscriptionService.getPaymentPlans();

			setPaymentPlans(response?.prematch || {});
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Variable that stores
	 * prematch trial monthly price
	 * by user's current currency
	 *
	 * @return {null} - if currency data is not available
	 * @return {number} - formatted price ready to display
	 */
	const monthlyTrialPrice = useMemo(() => {
		if (!currency) return null;

		const unformattedMonthlyTrialPrice = subscription.getTrialAmount(currency) || 0;

		return unformattedMonthlyTrialPrice / 100;
	}, [currency]);

	/**
	 * Variable that stores
	 * trial period in days for prematch promocode
	 *
	 * @return {null} - if no promocode details loaded from request
	 * @return {number} - formatted trial period in days
	 */
	const trialPeriod = useMemo(() => {
		if (!promoCodeDetails) return null;

		const unformattedTrialPeriod = promoCodeDetails.firstRecurringPaymentOverrideSeconds || 0;

		return unformattedTrialPeriod / 86400;
	}, [promoCodeDetails]);

	useEffect(() => {
		setTimeout(() => {
			sendEvent('Loadpage Prematch Landing');
		}, PAGE_LOAD_LOG_EVENT_DELAY);
	}, []);

	const checkingAuthorization = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn && isBrowser) {
			window.location.href = `${window.location.protocol}//app.${window.location.hostname}/prematch`;
		} else {
			init();
		}
	};

	useEffect(() => {
		if (modal) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'scroll';
		}
	}, [modal]);

	const canonicalLinks = useMemo(() => {
		if (isBrowser) {
			const location = window.location.origin;

			return {
				ru: `${location}/ru/prematch/`,
				en: `${location}/prematch/`,
			};
		}

		return {
			ru: 'https://scope.gg/ru/prematch',
			en: 'https://scope.gg/prematch',
		};
	}, []);

	const hreflangLinks = useMemo(() => {
		if (isBrowser) {
			const location = window.location.origin;

			return {
				ru: `${location}/prematch`,
				en: `${location}/ru/prematch`,
			};
		}

		return {
			ru: 'https://scope.gg/prematch',
			en: 'https://scope.gg/ru/prematch',
		};
	}, []);

	const hreflang = useMemo(() => {
		return {
			ru: 'en',
			en: 'ru',
		};
	}, []);

	const Modal = useMemo(() => {
		switch (modal) {
			case 'auth':
				return AuthSourceChoiceModal;
			default:
				break;
		}
	}, [modal]);

	const handleAuthButtonClick = ({ modalType, location }) => {
		if (modalType === 'auth') {
			sendEvent('Prematch Landing / Click Buy Button', { location });

			sendDataLayerEvent({
				event: 'funnel_prematch',
				event_id: 'd-v6-e6',
				event_cat: 'prematch_subscription_btn',
				event_name: 'purchase_click_landing_btn',
			});
		}

		const in30Minutes = new Date(new Date().getTime() + 30 * 60 * 1000);

		Cookies.set('redirectUrl', '/subscription', {
			path: '/',
			domain: process.env.GATSBY_APP_DOMAIN,
			expires: in30Minutes,
		});

		Cookies.set('from-prematch-landing', `prematch.${process.env.GATSBY_APP_DOMAIN}`, {
			path: '/',
			domain: process.env.GATSBY_APP_DOMAIN,
			expires: in30Minutes,
		});

		setModal(modalType);
	};

	const handleAuth = ({ modalType, location }) => {
		if (modalType === 'auth') {
			sendEvent('Prematch Landing / Click Buy Button', { location });

			sendDataLayerEvent({
				event: 'funnel_prematch',
				event_id: 'd-v7-e7',
				event_cat: 'prematch_subscription_btn',
				event_name: 'sign_up_click_block_landing',
			});
		}

		setModal(modalType);
	};

	return (
		<Layout>
			<Helmet>
				<title>{intl.formatMessage({ id: 'prematch.FirstOne' })}</title>
				{process.env.GATSBY_ACTIVE_ENV !== 'production' ? <meta name="robots" content="noindex, nofollow" /> : null}
				<meta name="description" content={intl.formatMessage({ id: 'prematch.SecondOne' })} />
				<link rel="icon" href="/favicon.ico" />
				{intl.locale === 'en' ? (
					<link rel="canonical" href="https://scope.gg/prematch/" />
				) : (
					<link rel="canonical" href={`https://scope.gg/${intl.locale}/prematch/`} />
				)}
				<link rel="alternate" hrefLang="x-default" href="https://scope.gg/prematch/" />
				<link rel="alternate" hrefLang="en" href="https://scope.gg/prematch/" />
				<link rel="alternate" hrefLang="ru" href="https://scope.gg/ru/prematch/" />
				<link rel="alternate" hrefLang="fr" href="https://scope.gg/fr/prematch/" />
				<link rel="alternate" hrefLang="pt" href="https://scope.gg/pt/prematch/" />
				<link rel="alternate" hrefLang="es" href="https://scope.gg/es/prematch/" />
			</Helmet>

			<div className="prematch-analytics-page">
				<Navbar />
				<HeroBlock
					onAuthClick={handleAuthButtonClick}
					faceItLink={faceItLink}
					paymentPlans={paymentPlans}
					currency={currency}
					monthlyTrialPrice={monthlyTrialPrice}
					trialPeriod={trialPeriod}
				/>
				<CarouselBlock />
				<div className="sticky-wrapper">
					<StepsBlock onAuthClick={handleAuth} />
					<SubscribeBlock
						onAuthClick={handleAuthButtonClick}
						faceItLink={faceItLink}
						paymentPlans={paymentPlans}
						currency={currency}
						monthlyTrialPrice={monthlyTrialPrice}
						trialPeriod={trialPeriod}
					/>
					<FAQBlock
						paymentPlans={paymentPlans}
						currency={currency}
						monthlyTrialPrice={monthlyTrialPrice}
						trialPeriod={trialPeriod}
					/>
					<Bottom />
					<Footer />
					<div className="sticky">
						<div
							className="sticky-button"
							onClick={() => {
								handleAuthButtonClick({ modalType: 'auth', location: 'chase' });
							}}
						>
							<span className="sticky-button-title">{intl.formatMessage({ id: 'prematch.PurchaseSubscription' })}</span>
						</div>
					</div>
				</div>

				{modal && (
					<Modal
						onClose={() => {
							setModal(false);
						}}
					/>
				)}
			</div>
		</Layout>
	);
};

export default PrematchAnalyticsPage;
