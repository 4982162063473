import React from 'react'
import ModalsContext from './ModalsContext'

export default App => class AppWithModals extends React.Component {
	static async getInitialProps(ctx) {
		let props = {}
		if (typeof App.getInitialProps === 'function') {
			props = await App.getInitialProps(ctx)
		}

		return props
	}

	constructor(props) {
		super(props)
		this.modalsRootRef = React.createRef()
	}

	render() {
		return (
			<React.Fragment>
				<ModalsContext.Provider value={this.modalsRootRef}>
					<App {...this.props} />
				</ModalsContext.Provider>
				<div ref={this.modalsRootRef} />
			</React.Fragment>
		)
	}
}
