import React, { useEffect, useState } from 'react';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import * as styles from '@components/molecules/SideActions/sideActions.module.scss';
import ScopeLogo from '@assets/images/scope-logo.svg';
// import * as styles from './header.module.scss';
import useResponsive from '@src/utils/useResponsive';
import Logo from '@components/molecules/Logo';
import MenuList from '@components/molecules/MenuList';
import SideActions from '@components/molecules/SideActions';

import XLogo from '@assets/images/landing/Xplay.svg';
import burger from '@assets/images/landing/burger.svg';
import crossIcon from '@assets/images/landing/cross-icon.svg';

const Navbar = () => {
	const intl = useIntl();

	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	useEffect(() => {
		if (showMobileMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'initial';
		}
	}, [showMobileMenu]);

	return (
		<header className="prematch-navbar-container">
			<div className="prematch-navbar-content">
				{!isTablet ? (
					<></>
				) : (
					// <div className="prematch-logo-container">
					// 	<div className="prematch-logo-wrapper">
					// 		<img src={ScopeLogo} alt="Scope.gg Prematch logo" />
					// 	</div>

					// 	<div className="prematch-logo-title">
					// 		<span className="prematch-logo-title-text">Prematch Analytics</span>
					// 	</div>
					// </div>
					<></>
				)}

				{!isMobile ? (
					<>
						{/* <div className="prematch-logo-container"> */}
						{/* <MenuList /> */}

						<div className="prematch-logo-container">
							<div className="prematch-logo-wrapper">
								<img src={ScopeLogo} alt="Scope.gg Prematch logo" />
							</div>

							<div className="prematch-logo-title">
								<span className="prematch-logo-title-text">Prematch Analytics</span>
							</div>
						</div>
						<SideActions />
						{/* </div> */}
					</>
				) : (
					<>
						<button
							onClick={() => setShowMobileMenu(true)}
							style={{ width: '36px', height: '36px', marginLeft: 'auto' }}
						>
							<img src={burger} alt="Burger menu" />
						</button>
					</>
				)}

				{isMobile && showMobileMenu ? (
					<div className="container-navbar">
						<aside className={`${styles.mobilenavbarMenu}${showMobileMenu ? ' open' : ''}`}>
							<button className={styles.mobilenavbarMenuClose} onClick={() => setShowMobileMenu(false)}>
								<img src={crossIcon} alt="SCOPE.GG Cross Icon" />
							</button>
							<div className={styles.logosContainer}>
								<Logo />
								{`${' X '}`}
								<a href="https://excorp.gg" target="_blank">
									<img src={XLogo} alt="EXCORP.GG LOGO" />
								</a>
							</div>
							<MenuList />
							<SideActions />
						</aside>
					</div>
				) : (
					<></>
				)}
			</div>
		</header>
	);
};

export default Navbar;
