import React, { Fragment } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import ModalLayout from './ModalLayout';

import SteamLogo from '../../../assets/images/logo-steam.svg';
import FaceitLogo from '../../../assets/images/logo-faceit.svg';

import cookie from 'js-cookie';
import { isBrowser } from '../../../utils/ssr';
import { sendEvent } from '../../../utils/amplitude';
import { sendDataLayerEvent } from '../../../utils/gtm';

const LENS_FEATURES = [
	{ id: 'dem', text: 'prematch.UnlimitedUpload' },
	{ id: 'hltv', text: 'prematch.UploadHltv' },
	{ id: 'grenades', text: 'prematch.CastomGrenades' },
	{ id: 'stats', text: 'prematch.DetailedStats' },
	{ id: 'teammates', text: 'prematch.AdvancedSearch' },
];

export default ({ onClose }) => {
	const intl = useIntl();

	const handleButtonClick = () => {
		onClose();
	};

	const getSourceAuthUrl = (id) => {
		if (isBrowser) {
			const appUrl = process.env.GATSBY_APP_URL;
			const backendUrl = process.env.GATSBY_API_URL;

			const faceItUrl = `${backendUrl}/faceIt/auth?successRedirectUrl=${appUrl}/auth-process?source=faceit&failureRedirectUrl=${appUrl}/auth-process?source=faceit`;
			const steamUrl = `${backendUrl}/steamProfiles/auth?redirectUrl=${appUrl}/auth-process?source=steam&backendUrl=${backendUrl}`;

			return id === 'steam' ? steamUrl : faceItUrl;
		}
	};

	const authSources = [
		{
			id: 'Steam',
			title: 'prematch.AuthThroughSteam',
			imgSrc: SteamLogo,
			imgAlt: 'Steam logo',
			href: getSourceAuthUrl('steam'),
		},
		{
			id: 'Faceit',
			title: 'prematch.AuthThroughFaceit',
			imgSrc: FaceitLogo,
			imgAlt: 'Faceit logo',
			href: getSourceAuthUrl('faceit'),
		},
	];

	const handleOnAuthSourceClick = (sourceId) => {
		sendEvent('Prematch Landing / Sign Up / Click Source', { Source: sourceId });

		const inTwoMinutes = new Date(new Date().getTime() + 2 * 60 * 1000);

		if (sourceId === 'Faceit') {
			sendDataLayerEvent({
				event: 'funnel_prematch',
				event_id: 'd-v8-e8',
				event_cat: 'prematch_subscription_btn',
				event_name: 'sign_up_faceit_module',
			});
		} else if (sourceId === 'Steam') {
			sendDataLayerEvent({
				event: 'funnel_prematch',
				event_id: 'd-v9-e9',
				event_cat: 'prematch_subscription_btn',
				event_name: 'sign_up_faceit_module',
			});
		}

		// cookie.set('redirectUrl', '/prematch', {
		// 	path: '/',
		// 	domain: process.env.GATSBY_APP_DOMAIN,
		// 	expires: inTwoMinutes,
		// });

		// cookie.set('preactivatedmodal', `prematch.${process.env.GATSBY_APP_DOMAIN}`, {
		// 	path: '/',
		// 	domain: process.env.GATSBY_APP_DOMAIN,
		// 	expires: inTwoMinutes,
		// });
	};

	return (
		<Fragment>
			<ModalLayout width="348px" onClose={handleButtonClick}>
				<div className="container-modal">
					<div className="auth-source-cards-wrapper">
						{authSources.map((source) => {
							return (
								<a
									key={source.id}
									href={source.href}
									className="auth-source-card-wrapper"
									onClick={() => handleOnAuthSourceClick(source.id)}
								>
									<img src={source.imgSrc} alt={source.imgAlt} width="104" height="104" />
									<div className="auth-source-card-text">{intl.formatMessage({ id: source.title })}</div>
								</a>
							);
						})}
					</div>
				</div>
			</ModalLayout>
		</Fragment>
	);
};
