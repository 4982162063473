import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import cookie from 'js-cookie';
import { isBrowser } from '../../../utils/ssr';
import getSymbolFromCurrency from 'currency-symbol-map';
import { sendEvent } from '../../../utils/amplitude';

import ArrowIcon from '../../../assets/images/grey-arrow.svg';

const HeroBlock = ({ onAuthClick, paymentPlans, currency, monthlyTrialPrice, trialPeriod }) => {
	const ref = useRef(null);
	const intl = useIntl();

	const [monthPrice, setMonthPrice] = useState(null);

	useEffect(() => {
		if (!currency) return;
		if (!paymentPlans) return;
		if (!paymentPlans.month) return;

		const { month } = paymentPlans;

		setMonthPrice(month[currency] / 100);
	}, [paymentPlans, currency]);

	useEffect(() => {
		// eslint-disable-next-line
		import('@lottiefiles/lottie-player');
	}, []);

	const fileNameByCurrentLocale = useMemo(() => {
		const fileName = intl.locale === 'ru' ? 'data2.json' : 'data_eng.json';

		return fileName;
	}, [intl.locale]);

	const handleButtonClick = () => {
		onAuthClick({ modalType: 'auth', location: 'first screen' });
	};

	return (
		<div className="hero-block-container">
			<div className="hero-block-content-wrapper">
				<div className="hero-block-content">
					<div className="hero-block-titles-wrapper">
						<div className="hero-block-title">
							<span className="hero-block-title-text">{intl.formatMessage({ id: 'prematch.HeroTitle' })}</span>
						</div>
						<div className="hero-block-subtitle">
							<span className="hero-block-subtitle-text">{intl.formatMessage({ id: 'prematch.HeroSubtitle' })}</span>
						</div>
					</div>

					<div className="hero-block-action-block">
						<button className="hero-block-button" onClick={handleButtonClick}>
							<span className="hero-block-button-title">
								{intl.formatMessage(
									{ id: 'prematch.PurchaseSubscription' },
									{
										trialPeriod: trialPeriod,
										trialPrice: monthlyTrialPrice,
										currencySymbol: getSymbolFromCurrency(currency),
									}
								)}
							</span>
						</button>
					</div>
				</div>

				<div className="hero-block-lottie-container">
					<lottie-player
						id="firstLottie"
						ref={ref}
						autoplay
						loop
						control
						mode="normal"
						transpartent
						src={`/prematch/${fileNameByCurrentLocale}`}
						style={{
							width: '655px',
							height: '655px',
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default HeroBlock;
