import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import GreenUpArrow from '../../../assets/images/green-up-arrow.svg';
import cookie from 'js-cookie';
import { sendEvent } from '../../../utils/amplitude';

const steps = [
	{
		id: 'do-auth',
		title: 'prematch.DoAuth',
	},
	{
		id: 'find-match',
		title: 'prematch.FindMatchOnFaceit',
	},
	{
		id: 'get-info',
		title: 'prematch.GetPrematchInfo',
	},
	{
		id: 'win-game',
		title: 'prematch.UseInfoAndWin',
	},
];

const StepsBlock = ({ onAuthClick }) => {
	const intl = useIntl();

	const handleButtonClick = () => {
		onAuthClick({ modalType: 'auth', location: 'auth' });
	};

	return (
		<div className="steps-block-container">
			<div className="steps-block-content-wrapper">
				<div className="steps-block-content-title">
					<span className="steps-block-title-text">{intl.formatMessage({ id: 'prematch.HowItWorks' })}</span>
				</div>
				<div className="steps-block-steps-wrapper">
					<div className="steps-block-steps">
						{steps.map((step, index) => {
							return (
								<div key={step.id}>
									<div className="steps-block-cell">
										<div className="steps-block-step-id">{`${intl.formatMessage({ id: 'prematch.Step' })} ${
											index + 1
										}`}</div>
										<div className="steps-block-step-content">
											{step.id === 'do-auth' ? (
												<div className="step-content-link-content">
													<button onClick={handleButtonClick}>
														<div className="step-content-link-wrapper">
															<span className="step-content-link">
																{intl.formatMessage({ id: 'prematch.doAuthorization' })}
															</span>
															<img src={GreenUpArrow} />
														</div>
													</button>

													<span className="step-content-text">{intl.formatMessage({ id: step.title })}</span>
												</div>
											) : (
												<span className="step-content-text">{intl.formatMessage({ id: step.title })}</span>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepsBlock;
